import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";
import ScrollToTop from "../components/scroll/ScrollToTop";
import AllService from "../pages/service/all-services";
const Home = lazy(() => import("../pages/Home"));
const WhyChooseUs = lazy(() => import("../pages/why-choose-us"));
const ContactUs = lazy(() => import("../pages/contact_us"));
const NewNavbar = lazy(() => import("../components/new-navbar/NewNavbar"));
const Leadership = lazy(() => import("../pages/leadership"));
// const Navbar = lazy(() => import('../components/navbar/Navbar'))
const Footer = lazy(() => import("../components/footer/Footer"));
const About = lazy(() => import("../pages/About"));
const Careers = lazy(() => import("../pages/careers"));
const Blog = lazy(() => import("../pages/Blog"));
const BlogDetails = lazy(() => import("../pages/BlogDetails"));
const Industries = lazy(() => import("../pages/Industries"));
const CaseStudies = lazy(() => import("../pages/CaseStudies"));
const CaseStudiesDetails = lazy(() => import("../pages/CaseStudiesDetails"));
const TechnologiesDetails = lazy(() => import("../pages/TechnologiesDetails"))
const IndustriesInfoPage = lazy(() => import("../pages/Industries-info"));
const TechnologiesPage = lazy(() => import("../pages/technologies"));
const OurMethodology = lazy(() => import("../pages/our-methodology"));
const TermsConditions = lazy(() => import("../pages/TermsConditions"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const Portfolio = lazy(() => import("../pages/Portfolio"));
const ServicesPage = lazy(() => import ('../pages/service'))

const NotFoundPage = lazy(() => import("../pages/notfound/NotFoundPage"));
// const SitemapPage = lazy(() => import('../pages/sitemap/SitemapPage'))

const routesView = () => {
  const routes = [
    {
      path: "/",
      exact: true,
      navbar: (props) => <NewNavbar active="Home" {...props} />,
      footer: () => <Footer />,
      main: (props) => <Home {...props} />,
    },
    {
      path: "/about",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <About {...props} />,
    },
    {
      path: "/why-choose-us",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <WhyChooseUs {...props} />,
    },
    {
      path: "/contact-us",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <ContactUs {...props} />,
    },
    {
      path: "/blog",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Blog {...props} />,
    },
    {
      path: "/blog/:id",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <BlogDetails {...props} />,
    },
    {
      path: "/portfolio",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Portfolio {...props} />,
    },
    {
      path: "/industries",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Industries {...props} />,
    },
    {
      path: "/industries/:industriesId",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <IndustriesInfoPage {...props} />,
    },
    {
      path: "/leadership",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Leadership {...props} />,
    },
    {
      path: "/careers",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Careers {...props} />,
    },
    {
      path: "/case-studies",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <CaseStudies {...props} />,
    },
    {
      path: "/case-studies/:id",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <CaseStudiesDetails {...props} />,
    },
    {
      path: "/services",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <ServicesPage {...props} />,
    },
    {
      path: "/services/:id",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <AllService {...props} />,
    },
   
    {
      path: "/technologies",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <TechnologiesPage {...props} />,
    }, 
     {
      path: "/technologies/:id",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <TechnologiesDetails  {...props} />,
    },
    {
      path: "/work-methodology",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <OurMethodology {...props} />,
    },
    {
      path: "/terms-and-conditions",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <TermsConditions {...props} />,
    },
    {
      path: "/privacy-policy",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <PrivacyPolicy {...props} />,
    },
    {
      navbar: (props) => <></>,
      footer: () => <></>,
      main: (props) => <NotFoundPage {...props} />,
    },
  ];

  return (
    <Suspense
      fallback={
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <PacmanLoader color="#19275F" size={40} />
        </div>
      }
    >
      <Router>
      <ScrollToTop />
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={(props) => <route.navbar {...props} />}
            />
          ))}
        </Switch>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={(props) => (
                <route.main subRoutes={route.routes} {...props} />
              )}
            />
          ))}
        </Switch>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={(props) => <route.footer {...props} />}
            />
          ))}
        </Switch>
      </Router>
    </Suspense>
  );
};

export default routesView;
