const ServicesArray = [
  {
    id: 1,
    title: "Web Development",
    image: "https://cdn-icons-png.freepik.com/512/1163/1163890.png",
    subLinks: [
      "Custom Development",
      "BackEnd Development",
      "FrontEnd Development",
      "Ruby On Rails Development",
      "Python Django Development",
      "React Development",
      "Angular Development",
    ],
    subHashLinks: [
      {
        id: 1,
        link: "Custom Development",
        hashLink: "/services/custom_development",
      },
      {
        id: 2,
        link: "Backend Development",
        hashLink: "/services/backend_development",
      },
      {
        id: 3,
        link: "Frontend Development",
        hashLink: "/services/frontend_development",
      },
      {
        id: 4,
        link: "Ruby On Rails Development",
        hashLink: "/services/ruby_on_rails_development",
      },
      {
        id: 5,
        link: "Python Django Development",
        hashLink: "/services/python_django_development",
      },
      {
        id: 6,
        link: "React Development",
        hashLink: "/services/react_development",
      },
      {
        id: 7,
        link: "Angular Development",
        hashLink: "/services/angular-development",
      },
      {
        id: 7,
        link: "AI Integration",
        hashLink: "/services/ai_integration",
      },
      {
        id: 7,
        link: "Cloud Based Web Solution",
        hashLink: "/services/cloud_web",
      },
    ],
    slug: "web_development",
  },
  {
    id: 2,
    title: "Mobile Development",
    image: "https://cdn-icons-png.flaticon.com/512/7075/7075373.png",
    subLinks: [
      "Android Development",
      "iOS Development",
      "Cross Platform Development",
      "React Native Development",
      "Enterprise Mobile App Development",
      "Hybrid Mobile App Development",
      "IoT Mobile App Development",
      "Cloud App Development",
    ],
    subHashLinks: [
      {
        id: 1,
        link: "Android Development",
        hashLink: "/services/android_development",
      },
      {
        id: 2,
        link: "iOS Development",
        hashLink: "/services/ios_development",
      },
      {
        id: 3,
        link: "Cross Platform Development",
        hashLink: "/services/cross_platform_development",
      },
      {
        id: 4,
        link: "React Native Development",
        hashLink: "/services/react_native_development",
      },
      {
        id: 5,
        link: "Enterprise Mobile App Development",
        hashLink: "/services/enterprise_mobile_development",
      },
      {
        id: 6,
        link: "Hybrid Mobile App Development",
        hashLink: "/services/hybrid_mobile_development",
      },
      {
        id: 7,
        link: "IoT Mobile App Development",
        hashLink: "/services/iot_mobile_development",
      },
      {
        id: 8,
        link: "Cloud App Development",
        hashLink: "/services/cloud_app_development",
      },
    ],
    slug: "mobile_development",
  },
  {
    id: 2,
    title: "AI & ML Solutions",
    image:
      "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWJyYWluLWNpcmN1aXQiPjxwYXRoIGQ9Ik0xMiA1YTMgMyAwIDEgMC01Ljk5Ny4xMjUgNCA0IDAgMCAwLTIuNTI2IDUuNzcgNCA0IDAgMCAwIC41NTYgNi41ODhBNCA0IDAgMSAwIDEyIDE4WiIvPjxwYXRoIGQ9Ik05IDEzYTQuNSA0LjUgMCAwIDAgMy00Ii8+PHBhdGggZD0iTTYuMDAzIDUuMTI1QTMgMyAwIDAgMCA2LjQwMSA2LjUiLz48cGF0aCBkPSJNMy40NzcgMTAuODk2YTQgNCAwIDAgMSAuNTg1LS4zOTYiLz48cGF0aCBkPSJNNiAxOGE0IDQgMCAwIDEtMS45NjctLjUxNiIvPjxwYXRoIGQ9Ik0xMiAxM2g0Ii8+PHBhdGggZD0iTTEyIDE4aDZhMiAyIDAgMCAxIDIgMnYxIi8+PHBhdGggZD0iTTEyIDhoOCIvPjxwYXRoIGQ9Ik0xNiA4VjVhMiAyIDAgMCAxIDItMiIvPjxjaXJjbGUgY3g9IjE2IiBjeT0iMTMiIHI9Ii41Ii8+PGNpcmNsZSBjeD0iMTgiIGN5PSIzIiByPSIuNSIvPjxjaXJjbGUgY3g9IjIwIiBjeT0iMjEiIHI9Ii41Ii8+PGNpcmNsZSBjeD0iMjAiIGN5PSI4IiByPSIuNSIvPjwvc3ZnPg==",
    subLinks: [
      "AI Driven Personalization",
      "AI for Chatbots & Conversational Interfaces",
      "AI for Data Analysis & Visualization",
      "AI for Model Training, Development & Maintenance",
      "AI-Powered Automation",
      "AI Strategy & Consulting ",
      "Machine Learning (ML) ",
      "Natural Language Processing (NLP)",
    ],
    subHashLinks: [
      {
        id: 1,
        link: "AI Driven Personalization",
        hashLink: "/services/ai_driven_presonalization",
      },
      {
        id: 2,
        link: "Chatbots & Conversational Interfaces",
        hashLink: "/services/chatbots_conversational_interfaces",
      },
      {
        id: 3,
        link: "Data Analysis & Visualization",
        hashLink: "/services/data_analysis_visualization",
      },
      {
        id: 4,
        link: "Model Training, Development, & Maintenance",
        hashLink: "/services/model_training",
      },
      {
        id: 4,
        link: "AI-Powered Automation",
        hashLink: "/services/ai_powered_automation",
      },
      {
        id: 4,
        link: "AI Strategy & Consulting",
        hashLink: "/services/startegy_consulting",
      },
      {
        id: 4,
        link: "Machine Learning",
        hashLink: "/services/ml_solutions",
      },
      {
        id: 4,
        link: "Natural Language Processing",
        hashLink: "/services/nlp",
      },
    ],
    slug: "ai_ml_solutions",
  },
  {
    id: 3,
    title: "Digital Marketing",
    image: "https://cdn-icons-png.freepik.com/512/945/945479.png",
    subLinks: ["SEO", "SEM", "SMM", "Content", "Email Marketing"],
    subHashLinks: [
      {
        id: 1,
        link: "SEO",
        hashLink: "/services/seo",
      },
      {
        id: 2,
        link: "SEM",
        hashLink: "/services/sem",
      },
      {
        id: 3,
        link: "SMM",
        hashLink: "/services/smm",
      },
      {
        id: 4,
        link: "Content Marketing",
        hashLink: "/services/content_marketing",
      },
      {
        id: 5,
        link: "E-Commerce Marketing",
        hashLink: "/services/e-commerce_marketing",
      },
      {
        id: 6,
        link: "Affliate Marketing",
        hashLink: "/services/affliate_marketing",
      },
      {
        id: 7,
        link: "Paid Social Advertising",
        hashLink: "/services/paid_social_advertising",
      },
      {
        id: 8,
        link: "Web Analytics",
        hashLink: "/services/web_analytics",
      },
      {
        id: 9,
        link: "Chat-Bot Marketing",
        hashLink: "/services/chat-bot_marketing",
      },
    ],
    slug: "digital_marketing",
  },
  {
    id: 4,
    title: "UI/UX Design",
    image: "https://cdn-icons-png.flaticon.com/512/1517/1517948.png",
    subLinks: [
      "Dashboard & Data Visualization",
      "Mobile App UI/UX Design",
      "User Experience (UX) Design",
      "User Interface (UI) Design",
      "User Research & Analysis",
      "Wep App UI/UX Design",
      "Wireframing & Prototyping",
    ],
    subHashLinks: [
      {
        id: 1,
        link: "Dashboard & Data Visualization Design",
        hashLink: "/services/dashboard_data_visualization",
      },
      {
        id: 2,
        link: "Mobile App UI/UX Design",
        hashLink: "/services/mobile_app_ui_ux_design",
      },
      {
        id: 3,
        link: "User Interface (UI) Design",
        hashLink: "/services/ui",
      },
      {
        id: 4,
        link: "User Experience (UX) Design",
        hashLink: "/services/ux",
      },
      {
        id: 4,
        link: "User Research & Analysis",
        hashLink: "/services/user_research_and_analysis",
      },
      {
        id: 4,
        link: "Wep App UI/UX Design",
        hashLink: "/services/web_app",
      },
      {
        id: 4,
        link: "Wireframing & Prototyping",
        hashLink: "/services/wireframing_and_prototyping",
      },
    ],
    slug: "ui_ux",
  },
  {
    id: 5,
    title: "Strategy & Architectural",
    image: "https://cdn-icons-png.freepik.com/512/5904/5904046.png",
    subLinks: [
      "API Strategy & Development",
      "Cloud Strategy & Architecture",
      "Data Architecture & Strategy",
      "DevOps Strategy & Implementation",
      "IT Strategy & Roadmap Development",
      "Software Architecture Design",
      "Solution Architecture",
    ],
    subHashLinks: [
      {
        id: 1,
        link: "API Strategy & Development",
        hashLink: "/services/api_strategy_and_development",
      },
      {
        id: 2,
        link: "Cloud Strategy & Architecture",
        hashLink: "/services/cloud_strategy_and_architecture",
      },
      {
        id: 3,
        link: "Data Architecture & Strategy",
        hashLink: "/services/data_architecture_and_strategy",
      },
      {
        id: 4,
        link: "DevOps Strategy & Implementation",
        hashLink: "/services/devops_strategy_and_implementation",
      },
      {
        id: 5,
        link: "IT Strategy & Roadmap Development",
        hashLink: "/services/it_strategy_and_roadmap_development",
      },
      {
        id: 6,
        link: "Software Architecture Design",
        hashLink: "/services/software_architecture_design",
      },
      {
        id: 6,
        link: "Solution Architecture",
        hashLink: "/services/solution_architecture",
      },
    ],
    slug: "strategy_and_architectural",
  },
  {
    id: 6,
    title: "Cloud DevOps",
    image: "https://cdn-icons-png.flaticon.com/512/115/115804.png",
    subLinks: [
      "CICD Pipeline Setup & Automation",
      "Cloud Infrastructure Management",
      "Cloud Migration & Integration",
      "Cloud Native Application Development",
      "Cloud Security",
      "Containerization & Obchestration",
      "Serverless Computing & Automation",
    ],
    subHashLinks: [
      {
        id: 1,
        link: "CICD Pipeline Setup & Automation",
        hashLink: "/services/cicd_pipeline",
      },
      {
        id: 2,
        link: "Cloud Infrastructure Management",
        hashLink: "/services/cloud_infrastructure",
      },
      {
        id: 3,
        link: "Cloud Migration & Integration",
        hashLink: "/services/cloud_migration_and_Integration",
      },
      {
        id: 4,
        link: "Cloud Native Application",
        hashLink: "/services/cloud_native_application_development",
      },
      {
        id: 4,
        link: "Cloud Security",
        hashLink: "/services/cloud_security",
      },
      {
        id: 4,
        link: "Containerization & Obchestration",
        hashLink: "/services/containerization_and_obchestration",
      },
      {
        id: 4,
        link: "Serverless Computing & Automation",
        hashLink: "/services/serverless_computing_and_atomation",
      },
    ],
    slug: "cloud_devOps",
  },
  {
    id: 7,
    title: "Data Extraction With AI",
    image: "https://cdn-icons-png.freepik.com/512/1309/1309155.png",
    subLinks: [
      "Data Extraction from Catalogs & Brochures",
      "Data Transformation & Normalization",
      "Documnet Classification & Categorization",
      "PDF Data Extraction",
      "Safety Data Sheets Extraction",
      "Technical Data Sheets Extraction",
    ],
    subHashLinks: [
      {
        id: 1,
        link: "Catalogs & Brochures",
        hashLink: "/services/de_catalogs_and_brouchers",
      },
      {
        id: 2,
        link: "Data Transformation & Normalization",
        hashLink: "/services/data_transformation_and_normalization",
      },
      {
        id: 3,
        link: "Document Classification & Categorization",
        hashLink: "/services/classification_and_categorization",
      },
      {
        id: 6,
        link: "PDF Data Extraction",
        hashLink: "/services/pdf_data_extraction",
      },
      {
        id: 4,
        link: "Safety Data Sheets Extraction",
        hashLink: "/services/sds_extraction",
      },
      {
        id: 5,
        link: "Technical Data Sheets Extraction",
        hashLink: "/services/tds_extraction",
      },
    ],
    slug: "data_extraction_ai",
  },
  {
    id: 8,
    title: "Staff Augmentation",
    image: "https://cdn-icons-png.freepik.com/512/4598/4598130.png",
    subLinks: [
      "Contract to Hire Staffing",
      "IT Staff Augmentation",
      "Onsite Team Augmentation",
      "Remote Team Augmentation",
      "Technology Specific Staffing",
    ],
    subHashLinks: [
      {
        id: 1,
        link: "Contract to Hire Staffing",
        hashLink: "/services/contract_hire_staffing",
      },
      {
        id: 2,
        link: "IT Staff Augmentation",
        hashLink: "/services/it_staff_augmentation",
      },
      {
        id: 3,
        link: "Onsite Team Augmentation",
        hashLink: "/services/onsite_team_augmentation",
      },
      {
        id: 4,
        link: "Remote Team Augmentation",
        hashLink: "/services/remote_team_augmentation",
      },
      {
        id: 5,
        link: "Technology Specific Staffing",
        hashLink: "/services/technology_specific_staffing",
      },
    ],
    slug: "staff_augmentation",
  },
];

export default ServicesArray;
