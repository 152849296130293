import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ServicesArray from "../../components/data/ServicesArray";

function Drawer({ data }) {
  const [expandedItem, setExpandedItem] = useState(null);
console.log(data)
  useEffect(() => {
    const matchingService = ServicesArray.find(
      (service) =>
        service.title === data ||
        service.subHashLinks.some((subLink) => subLink.link === data)
    );
    if (matchingService) {
      setExpandedItem(matchingService.title);
    }
  }, [data]);

  const handleToggle = (item) => {
    setExpandedItem((prev) => (prev === item ? null : item));
  };

  const linkStyle = {
    textDecoration: "none",
    color: "inherit",
    width: "100%",
  };

  const isHighlighted = (item) => item === data;

  const highlightedStyle = {
    backgroundColor: "#f0f0f0",
    color: "#1976d2",
    fontWeight: "bold",
  };

  return (
    <div
      style={{
        width: "320px",
        backgroundColor: "#fff",
        position: "sticky",
        top: 100,
        height: "100vh",
        overflowY: "auto",
        fontSize: "17px",
        letterSpacing: "0em",
        lineHeight: 1.78,
        fontWeight: "500",
        fontFamily: "CerebriSansPro-Regular",
        textAlign: "justify",
      }}
    >
      <List>
        {ServicesArray.map((service) => (
          <React.Fragment key={service.id} >
            <ListItem
              button
              onClick={() => handleToggle(service.title)}
              style={isHighlighted(service.title) ? highlightedStyle : null}
            >
              <Link to={`/services/${service.slug}`} style={linkStyle}>
                <ListItemText primary={service.title} />
              </Link>
              {expandedItem === service.title ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </ListItem>
            <Collapse
              in={expandedItem === service.title}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {service.subHashLinks.map((subLink) => (
                  <Link
                    to={subLink.hashLink}
                    style={linkStyle}
                    key={subLink.id}
                  >
                    <ListItem
                      button
                      style={{
                        paddingLeft: 40,
                        ...(isHighlighted(subLink.link) ? highlightedStyle : null),
                      }}
                    >
                      <ListItemText primary={subLink.link} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

export default Drawer;
