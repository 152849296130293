export const progtonData = {
  website: {
    url: "www.progton.com",
    title:
      "Progton: Leading Innovation in Web & Mobile Development, AI, and Cloud Solutions - Bengaluru, India",
    description:
      "Progton: experts in Ruby on Rails, Python, React, and AWS. We offer custom Web & Mobile Development, AI, and Cloud Services tailored for industries like Chemicals, Data Scraping, Advertising, and more, positioning us as your partner in transformative digital solutions.",
    canonical: "https://www.progton.com",
  },
  about: {
    url: "https://www.progton.com/about",
    title:
      "About Progton: Our Journey, Mission, and Expertise in Software Development",
    description:
      "Learn about Progton's journey, our mission, and our expertise in Web & Mobile Development, AI, and Cloud Services. Discover how we empower industries like Chemicals, Golf Sports and more with innovative digital solutions.",
    canonical: "https://www.progton.com/about",
  },
  leadership: {
    url: "https://www.progton.com/leadership",
    title:
      "Progton Leadership: Meet Our Leaders of Experts in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Meet the Progton Leadership team, experts in Ruby on Rails, Python, React, and AWS. Learn how our team of professionals in Web & Mobile Development, AI, and Cloud Services can help you transform your business.",
    canonical: "https://www.progton.com/leadership",
  },
  whychooseus: {
    url: "https://www.progton.com/why-choose-us",
    title:
      "Why Choose Progton: Our Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover why Progton is the right choice for your Web & Mobile Development, AI, and Cloud Services needs. Learn how our expertise in Ruby on Rails, Python, React, and AWS can help you transform your business.",
    canonical: "https://www.progton.com/why-choose-us",
  },
  workmethodology: {
    url: "https://www.progton.com/work-methodology",
    title:
      "Progton Work Methodology: Our Approach to Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Learn about Progton's work methodology, our approach to Web & Mobile Development, AI, and Cloud Services. Discover how our expertise in Ruby on Rails, Python, React, and AWS can help you transform your business.",
    canonical: "https://www.progton.com/work-methodology",
  },
  contactus: {
    url: "https://www.progton.com/contact-us",
    title:
      "Contact Progton: Get in Touch with Our Experts in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Get in touch with Progton's experts in Ruby on Rails, Python, React, and AWS. Learn how our team of professionals in Web & Mobile Development, AI, and Cloud Services can help you transform your business.",
    canonical: "https://www.progton.com/contact-us",
  },
  webdevelopment: {
    url: "https://www.progton.com/services/web-development",
    title:
      "Web Development Services: Web Application Development Solutions by Progton",
    description:
      "Progton offers custom Web Development Services tailored for industries like Chemicals, Data Scraping, Advertising, and more. Discover how our expertise in Ruby on Rails, Python, React, and AWS can help you transform your business.",
    canonical: "https://www.progton.com/services/web-development",
  },
  customdevelopment: {
    url: "https://www.progton.com/services/custom-development",
    title:
      "Custom Development Services: Custom Software Development Solutions by Progton",
    description:
      "Progton offers custom Software Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in Ruby on Rails, Python, React, and AWS can help you transform your business.",
    canonical: "https://www.progton.com/services/custom-development",
  },
  backenddevelopment: {
    url: "https://www.progton.com/services/backend-development",
    title:
      "Backend Development Services: Custom Backend Development Solutions by Progton",
    description:
      "Progton offers custom Backend Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in Ruby on Rails, Python, and AWS can help you transform your business.",
    canonical: "https://www.progton.com/services/backend-development",
  },
  frontenddevelopment: {
    url: "https://www.progton.com/services/frontend-development",
    title:
      "Frontend Development Services: Custom Frontend Development Solutions by Progton",
    description:
      "Progton offers custom Frontend Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in React can help you transform your business.",
    canonical: "https://www.progton.com/services/frontend-development",
  },
  rubyonrailsdevelopment: {
    url: "https://www.progton.com/services/ruby-on-rails-development",
    title:
      "Ruby on Rails Development Services: Custom Ruby on Rails Development Solutions by Progton",
    description:
      "Progton offers custom Ruby on Rails Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in Ruby on Rails can help you transform your business.",
    canonical: "https://www.progton.com/services/ruby-on-rails-development",
  },
  pythondjangodevelopment: {
    url: "https://www.progton.com/services/python-django-development",
    title:
      "Python Django Development Services: Custom Python Django Development Solutions by Progton",
    description:
      "Progton offers custom Python Django Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in Python can help you transform your business.",
    canonical: "https://www.progton.com/services/python-django-development",
  },
  reactdevelopment: {
    url: "https://www.progton.com/services/react-development",
    title:
      "React Development Services: Custom React Development Solutions by Progton",
    description:
      "Progton offers custom React Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in React can help you transform your business.",
    canonical: "https://www.progton.com/services/react-development",
  },
  angulardevelopment: {
    url: "https://www.progton.com/services/angular-development",
    title:
      "Angular Development Services: Custom Angular Development Solutions by Progton",
    description:
      "Progton offers custom Angular Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in Angular can help you transform your business.",
    canonical: "https://www.progton.com/services/angular-development",
  },
  mobiledevelopment: {
    url: "https://www.progton.com/services/mobile-development",
    title:
      "Mobile Development Services: Mobile Application Development Solutions by Progton",
    description:
      "Progton offers custom Mobile Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in React Native, Android and IOS can help you transform your business.",
    canonical: "https://www.progton.com/services/mobile-development",
  },
  androiddevelopment: {
    url: "https://www.progton.com/services/android-development",
    title:
      "Android Development Services: Custom Android Development Solutions by Progton",
    description:
      "Progton offers custom Android Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in Android can help you transform your business.",
    canonical: "https://www.progton.com/services/android-development",
  },
  iosdevelopment: {
    url: "https://www.progton.com/services/ios-development",
    title:
      "IOS Development Services: Custom IOS Development Solutions by Progton",
    description:
      "Progton offers custom IOS Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in IOS can help you transform your business.",
    canonical: "https://www.progton.com/services/ios-development",
  },
  crossplatformdevelopment: {
    url: "https://www.progton.com/services/cross-platform-development",
    title:
      "Cross Platform Development Services: Custom Cross Platform Development Solutions by Progton",
    description:
      "Progton offers custom Cross Platform Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in React Native, Flutter, can help you transform your business.",
    canonical: "https://www.progton.com/services/cross-platform-development",
  },
  reactnativedevelopment: {
    url: "https://www.progton.com/services/react-native-development",
    title:
      "React Native Development Services: Custom React Native Development Solutions by Progton",
    description:
      "Progton offers custom React Native Development Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in React Native can help you transform your business.",
    canonical: "https://www.progton.com/services/react-native-development",
  },
  digitalmarketing: {
    url: "https://www.progton.com/services/digital-marketing",
    title: "Digital Marketing Services: Digital Marketing Solutions by Progton",
    description:
      "Progton offers custom Digital Marketing Services tailored for industries like Chemicals, Data Scraping, Advertising, Golf Sports and more. Discover how our expertise in SEO, SMM, SEM, and Email Marketing can help you transform your business.",
    canonical: "https://www.progton.com/services/digital-marketing",
  },

  seo: {
    url: "https://www.progton.com/services/seo",
    title: "SEO Services: SEO Solutions by Progton",
    description:
      "Progton offers custom SEO Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in SEO can help you transform your business.",
    canonical: "https://www.progton.com/services/seo",
  },

  sem: {
    url: "https://www.progton.com/services/sem",
    title: "SEM Services: SEM Solutions by Progton",
    description:
      "Progton offers custom SEM Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in SEM can help you transform your business.",
    canonical: "https://www.progton.com/services/sem",
  },

  ppc: {
    url: "https://www.progton.com/services/ppc",
    title: "PPC Services: PPC Solutions by Progton",
    description:
      "Progton offers custom PPC Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in PPC can help you transform your business.",
    canonical: "https://www.progton.com/services/ppc",
  },

  contentmarketing: {
    url: "https://www.progton.com/services/content-marketing",
    title: "Content Marketing Services: Content Marketing Solutions by Progton",
    description:
      "Progton offers custom Content Marketing Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Content Marketing can help you transform your business.",
    canonical: "https://www.progton.com/services/content-marketing",
  },
  emailmarketing: {
    url: "https://www.progton.com/services/email-marketing",
    title: "Email Marketing Services: Email Marketing Solutions by Progton",
    description:
      "Progton offers custom Email Marketing Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Email Marketing can help you transform your business.",
    canonical: "https://www.progton.com/services/email-marketing",
  },

  socialmediamarketing: {
    url: "https://www.progton.com/services/social-media-marketing",
    title:
      "Social Media Marketing Services: Social Media Marketing Solutions by Progton",
    description:
      "Progton offers custom Social Media Marketing Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Social Media Marketing can help you transform your business.",
    canonical: "https://www.progton.com/services/social-media-marketing",
  },
  uiandux: {
    url: "https://www.progton.com/services/ui-and-ux",
    title: "UI/UX Services: UI/UX Solutions by Progton",
    description:
      "Progton offers custom UI/UX Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in UI/UX can help you transform your business.",
    canonical: "https://www.progton.com/services/ui-and-ux",
  },
  designconsultation: {
    url: "https://www.progton.com/services/design-consultation",
    title:
      "Design Consultation Services: Design Consultation Solutions by Progton",
    description:
      "Progton offers custom Design Consultation Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Design Consultation can help you transform your business.",
    canonical: "https://www.progton.com/services/design-consultation",
  },
  uiuxdesign: {
    url: "https://www.progton.com/services/ui-ux-design",
    title: "UI/UX Design Services: UI/UX Design Solutions by Progton",
    description:
      "Progton offers custom UI/UX Design Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in UI/UX Design can help you transform your business.",
    canonical: "https://www.progton.com/services/ui-ux-design",
  },
  userresearchandfeedback: {
    url: "https://www.progton.com/services/user-research-and-feedback",
    title:
      "User Research and Feedback Services: User Research and Feedback Solutions by Progton",
    description:
      "Progton offers custom User Research and Feedback Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in User Research and Feedback can help you transform your business.",
    canonical: "https://www.progton.com/services/user-research-and-feedback",
  },
  wireframingandmockups: {
    url: "https://www.progton.com/services/wire-framing-and-mockups",
    title:
      "Wire Framing and Mockups Services: Wire Framing and Mockups Solutions by Progton",
    description:
      "Progton offers custom Wire Framing and Mockups Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Wire Framing and Mockups can help you transform your business.",
    canonical: "https://www.progton.com/services/wire-framing-and-mockups",
  },
  strategyandarchitectural: {
    url: "https://www.progton.com/services/strategy-and-architectural",
    title:
      "Strategy and Architectural Consultation Services: Strategy and Architectural Consultation Solutions by Progton",
    description:
      "Progton offers custom Strategy and Architectural Consultation Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Strategy and Architectural Consultation can help you transform your business.",
    canonical:
      "https://www.progton.com/services/strategy-and-architectural",
  },
  digitalstrategyandconsultation: {
    url: "https://www.progton.com/services/digital-strategy-and-consultation",
    title:
      "Digital Strategy and Consultation Services: Digital Strategy and Consultation Solutions by Progton",
    description:
      "Progton offers custom Digital Strategy and Consultation Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Digital Strategy and Consultation can help you transform your business.",
    canonical:
      "https://www.progton.com/services/digital-strategy-and-consultation",
  },
  productresearchandplanning: {
    url: "https://www.progton.com/services/product-research-and-planning",
    title:
      "Product Research and Planning Services: Product Research and Planning Solutions by Progton",
    description:
      "Progton offers custom Product Research and Planning Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Product Research and Planning can help you transform your business.",
    canonical: "https://www.progton.com/services/product-research-and-planning",
  },
  rubyonrailsconsulting: {
    url: "https://www.progton.com/services/ruby-on-rails-consulting",
    title:
      "Ruby on Rails Consulting Services: Ruby on Rails Consulting Solutions by Progton",
    description:
      "Progton offers custom Ruby on Rails Consulting Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Ruby on Rails Consulting can help you transform your business.",
    canonical: "https://www.progton.com/services/ruby-on-rails-consulting",
  },
  applicationarchitecting: {
    url: "https://www.progton.com/services/application-architecting",
    title:
      "Application Architecting Services: Application Architecting Solutions by Progton",
    description:
      "Progton offers custom Application Architecting Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Application Architecting can help you transform your business.",
    canonical: "https://www.progton.com/services/application-architecting",
  },
  schemadevelopment: {
    url: "https://www.progton.com/services/schema-development",
    title:
      "Schema Development Services: Schema Development Solutions by Progton",
    description:
      "Progton offers custom Schema Development Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Schema Development can help you transform your business.",
    canonical: "https://www.progton.com/services/schema-development",
  },
  apidevelopment: {
    url: "https://progton.com/services/api-development",
    title: "API Development Services: API Development Solutions by Progton",
    description:
      "Progton offers custom API Development Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in API Development can help you transform your business.",
    canonical: "https://progton.com/services/api-development",
  },
  clouddevOps: {
    url: "https://www.progton.com/services/cloud-devOps",
    title: "Cloud DevOps Services: Cloud DevOps Solutions by Progton",
    description:
      "Progton offers custom Cloud DevOps Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Cloud DevOps using AWS and more can help you transform your business.",
    canonical: "https://www.progton.com/services/cloud-devOps",
  },
  deploymentandmaintenance: {
    url: "https://www.progton.com/services/deployment-and-maintenance",
    title:
      "Deployment and Maintenance Services: Deployment and Maintenance Solutions by Progton",
    description:
      "Progton offers custom Deployment and Maintenance Services using AWS, Kubernetes, and Docker. Discover how our expertise in Deployment and Maintenance using can help you transform your business.",
    canonical: "https://www.progton.com/services/deployment-and-maintenance",
  },
  containerisation: {
    url: "https://www.progton.com/services/containerisation",
    title: "Containerisation Services: Containerisation Solutions by Progton",
    description:
      "Progton offers custom Containerisation Services using Docker, Kubernetes, and more. Discover how our expertise in Containerisation can help you transform your business.",
    canonical: "https://www.progton.com/services/containerisation",
  },
  scalingandoptimisation: {
    url: "https://www.progton.com/services/scaling-and-optimisation",
    title:
      "Scaling and Optimisation Services: Scaling and Optimisation Solutions by Progton",
    description:
      "Progton offers custom Scaling and Optimisation Services using AWS, Kubernetes, and more. Discover how our expertise in Scaling and Optimisation can help you transform your business.",
    canonical: "https://www.progton.com/services/scaling-and-optimisation",
  },
  securityandmonitoring: {
    url: "https://www.progton.com/services/security-and-monitoring",
    title:
      "Security and Monitoring Services: Security and Monitoring Solutions by Progton",
    description:
      "Progton offers custom Security and Monitoring Services using AWS, Kubernetes, and more. Discover how our expertise in Security and Monitoring can help you transform your business.",
    canonical: "https://www.progton.com/services/security-and-monitoring",
  },

  dataservices: {
    url: "https://www.progton.com/services/data-services",
    title: "Data Services: Data Solutions by Progton",
    description:
      "Progton offers custom Data Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Data Services can help you transform your business.",
    canonical: "https://www.progton.com/services/data-services",
  },
  dataresearchandanalytics: {
    url: "https://www.progton.com/services/data-research-and-analytics",
    title:
      "Data Research and Analytics Services: Data Research and Analytics Solutions by Progton",
    description:
      "Progton offers custom Data Research and Analytics Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Data Research and Analytics can help you transform your business.",
    canonical: "https://www.progton.com/services/data-research-and-analytics",
  },
  dataextractionandscraping: {
    url: "https://www.progton.com/services/data-extraction-and-scraping",
    title:
      "Data Extraction and Scraping Services: Data Extraction and Scraping Solutions by Progton",
    description:
      "Progton offers custom Data Extraction and Scraping Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Data Extraction and Scraping can help you transform your business.",
    canonical: "https://www.progton.com/services/data-extraction-and-scraping",
  },
  datacollection: {
    url: "https://www.progton.com/services/data-collection",
    title: "Data Collection Services: Data Collection Solutions by Progton",
    description:
      "Progton offers custom Data Collection Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Data Collection can help you transform your business.",
    canonical: "https://www.progton.com/services/data-collection",
  },
  datamigration: {
    url: "https://www.progton.com/services/data-migration",
    title: "Data Migration Services: Data Migration Solutions by Progton",
    description:
      "Progton offers custom Data Migration Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Data Migration can help you transform your business.",
    canonical: "https://www.progton.com/services/data-migration",
  },
  datanormalisation: {
    url: "https://www.progton.com/services/data-normalisation",
    title:
      "Data Normalisation Services: Data Normalisation Solutions by Progton",
    description:
      "Progton offers custom Data Normalisation Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Data Normalisation can help you transform your business.",
    canonical: "https://www.progton.com/services/data-normalisation",
  },
  dataanalysis: {
    url: "https://www.progton.com/services/data-analysis",
    title: "Data Analysis Services: Data Analysis Solutions by Progton",
    description:
      "Progton offers custom Data Analysis Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Data Analysis can help you transform your business.",
    canonical: "https://www.progton.com/services/data-analysis",
  },
  staffaugmentation: {
    url: "https://www.progton.com/services/staff-augmentation",
    title:
      "Staff Augmentation Services: Staff Augmentation Solutions by Progton",
    description:
      "Progton offers custom Staff Augmentation Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Staff Augmentation can help you transform your business.",
    canonical: "https://www.progton.com/services/staff-augmentation",
  },
  hireateam: {
    url: "https://www.progton.com/services/hire-a-team",
    title: "Hire a Team Services: Hire a Team Solutions by Progton",
    description:
      "Progton offers custom Hire a Team Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Hire a Team can help you transform your business.",
    canonical: "https://www.progton.com/services/hire-a-team",
  },
  hireadeveloper: {
    url: "https://www.progton.com/services/hire-a-developer",
    title: "Hire a Developer Services: Hire a Developer Solutions by Progton",
    description:
      "Progton offers custom Hire a Developer Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Hire a Developer can help you transform your business.",
    canonical: "https://www.progton.com/services/hire-a-developer",
  },
  buildyourownteam: {
    url: "https://www.progton.com/services/build-your-own-team",
    title:
      "Build Your Own Team Services: Build Your Own Team Solutions by Progton",
    description:
      "Progton offers custom Build Your Own Team Services tailored for industries like Chemicals, e-commerce, Advertising, Golf Sports and more. Discover how our expertise in Build Your Own Team can help you transform your business.",
    canonical: "https://www.progton.com/services/build-your-own-team",
  },

  industries: {
    url: "https://www.progton.com/industries",
    title:
      "Industries We Serve: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help industries like Chemicals, Data Scraping, Advertising, Golf Sports, E-Commerce and more transform their business.",
    canonical: "https://www.progton.com/industries",
  },  
  services: {
    url: "https://www.progton.com/services",
    title:
      "Services We Serve: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services ",
    canonical: "https://www.progton.com/services",
  },

  chemicalindustry: {
    url: "https://www.progton.com/industries/chamical-industry",
    title:
      "Chemical Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help Chemical Industry transform their business.",
    canonical: "https://www.progton.com/industries/chamical-industry",
  },

  realestate: {
    url: "https://www.progton.com/industries/real-estate",
    title:
      "Real Estate Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help Real Estate Industry transform their business.",
    canonical: "https://www.progton.com/industries/real-estate",
  },

  mediaanddigitalmarketing: {
    url: "https://www.progton.com/industries/media-and-digital-marketing",
    title:
      "Media and Digital Marketing Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help Media and Digital Marketing Industry transform their business.",
    canonical: "https://www.progton.com/industries/media-and-digital-marketing",
  },

  retail: {
    url: "https://www.progton.com/industries/retail",
    title:
      "Retail and E-Commerce Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help Retail and E-Commerce Industry transform their business.",
    canonical: "https://www.progton.com/industries/retail",
  },

  healthcare: {
    url: "https://www.progton.com/industries/health-care",
    title:
      "Health Care Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help Health Care Industry transform their business.",
    canonical: "https://www.progton.com/industries/health-care",
  },

  itindustry: {
    url: "https://www.progton.com/industries/it-industry",
    title:
      "IT Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help IT Industry transform their business.",
    canonical: "https://www.progton.com/industries/it-industry",
  },
  logistics: {
    url: "https://www.progton.com/industries/logistics",
    title:
      "Logistics Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help Logistics Industry transform their business.",
    canonical: "https://www.progton.com/industries/logistics",
  },
  automobile: {
    url: "https://www.progton.com/industries/automobile",
    title:
      "Automobile Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help Automobile Industry transform their business.",
    canonical: "https://www.progton.com/industries/automobile",
  },
  foodandhopitality: {
    url: "https://www.progton.com/industries/food-and-hopitality",
    title:
      "Food and Hospitality Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help Food and Hospitality Industry transform their business.",
    canonical: "https://www.progton.com/industries/food-and-hopitality",
  },
  edutech: {
    url: "https://www.progton.com/industries/edutech",
    title:
      " Edutech Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      " Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help Edutech Industry transform their business.",
    canonical: "https://www.progton.com/industries/edutech",
  },

  ecommerce: {
    url: "https://www.progton.com/industries/e-commerce",
    title:
      "E-Commerce Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help E-Commerce Industry transform their business.",
    canonical: "https://www.progton.com/industries/e-commerce",
  },
  banking: {
    url: "https://www.progton.com/industries/banking",
    title:
      "Banking Industry Solutions: Progton's Expertise in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover how Progton's expertise in Web & Mobile Development, AI, and Cloud Services can help Banking Industry transform their business.",
    canonical: "https://www.progton.com/industries/banking",
  },
  casestudies: {
    url: "https://www.progton.com/case-studies",
    title:
      "Case Studies: Progton's Success Stories in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover Progton's success stories in Web & Mobile Development, AI, and Cloud Services. Learn how we have helped industries like Chemicals, Data Scraping, Advertising, Golf Sports and more transform their business.",
    canonical: "https://www.progton.com/case-studies",
  },
  mdscpareview: {
    url: "https://www.progton.com/case-studies/mds-cpa-review",
    title:
      "MDS CPA Review Case Study: Progton's Success Stories in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover Progton's success stories in Web & Mobile Development, AI, and Cloud Services. Learn how we have helped MDS CPA Review transform their business.",
    canonical: "https://www.progton.com/case-studies/mds-cpa-review",
  },
  decryptit: {
    url: "https://www.progton.com/case-studies/decrypt-it",
    title:
      "Decrypt IT Case Study: Progton's Success Stories in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover Progton's success stories in Web & Mobile Development, AI, and Cloud Services. Learn how we have helped Decrypt IT transform their business.",
    canonical: "https://www.progton.com/case-studies/decrypt-it",
  },
  mindswarms: {
    url: "https://www.progton.com/case-studies/mindswarms",
    title:
      "Mindswarms Case Study: Progton's Success Stories in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover Progton's success stories in Web & Mobile Development, AI, and Cloud Services. Learn how we have helped Mindswarms transform their business.",
    canonical: "https://www.progton.com/case-studies/mindswarms",
  },
  fabric: {
    url: "https://www.progton.com/case-studies/fabric",
    title:
      "Fabric Case Study: Progton's Success Stories in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover Progton's success stories in Web & Mobile Development, AI, and Cloud Services. Learn how we have helped Fabric transform their business.",
    canonical: "https://www.progton.com/case-studies/fabric",
  },
  skillsarena: {
    url: "https://www.progton.com/case-studies/skillsarena",
    title:
      "Skillsarena Case Study: Progton's Success Stories in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover Progton's success stories in Web & Mobile Development, AI, and Cloud Services. Learn how we have helped Skillsarena transform their business.",
    canonical: "https://www.progton.com/case-studies/skillsarena",
  },
  blog: {
    url: "https://www.progton.com/blog",
    title:
      "Progton Blog: Insights on Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover Progton's blog for insights on Web & Mobile Development, AI, and Cloud Services. Learn how we have helped industries like Chemicals, Data Scraping, Advertising, Golf Sports and more transform their business.",
    canonical: "https://www.progton.com/blog",
  },
  careers: {
    url: "https://www.progton.com/careers",
    title:
      "Careers at Progton: Join Our Team of Experts in Web & Mobile Development, AI, and Cloud Solutions",
    description:
      "Discover career opportunities at Progton for experts in Web & Mobile Development, AI, and Cloud Services. Learn how you can join our team and help industries like Chemicals, Data Scraping, Advertising, Golf Sports and more transform their business.",
    canonical: "https://www.progton.com/careers",
  },
};
