import { Container, makeStyles } from '@material-ui/core'
import React from 'react'



const useStyles = makeStyles(theme => ({
    titleSection : {
        backgroundImage: "url('https://assets.progton.com/headerbgimg.png')",
        backgroundColor : "#f6f2ed",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundAttachment: "scroll",
        borderBottomWidth: "0px",
        borderBottomColor:"rgba(0, 0, 0, 0)",
        paddingTop: "30px",
        paddingBottom: "30px",
        backgroundSize: 'cover',
    },
    heading : {
        fontSize : "64px",
        color : "#fff",
        lineHeight : "1.13",
        fontWeight : "700",
        textAlign : "center",
        paddingBottom : "20px",
        [theme.breakpoints.down(1200)]: {
            fontSize : "54px",
        },
        [theme.breakpoints.down(992)]: {
            fontSize : "44px",
        },
        [theme.breakpoints.down(768)]: {
            fontSize : "34px",
        }
    },
    subHeader : {
        fontSize : "24px",
        fontWeight : "500",
        lineHeight : "1.67",
        color : "#fff",
    },
    link : {
        textDecoration : "none",
        color : "#fff"
    }
}))

const ServiceTitleBanner = (props) => {
    const classes = useStyles()

    return (
       <div>
            <div className = {classes.titleSection} >
                <Container>
                    <h1 className = {classes.heading}>
                        {props.link && props.link}
                    </h1>
                    <div style = {{textAlign : "center"}} >
                        <span className = {classes.subHeader} > 
                            <a href = "/"  className = {classes.link} >Home</a> 
                            /  {props.link && props.link}
                        </span>
                    </div>
                </Container>
            </div>
       </div>
    )
}

export default ServiceTitleBanner