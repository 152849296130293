import { Container, Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
  },
  text: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    color: "#333333",
    fontFamily: "'Montserrat', sans-serif",
    textAlign: "center",
  },
  oopsText: {
    fontFamily: "'Montserrat', sans-serif",
    fontStyle: "noraml",
    fontWeight: "bold",
    fontSize: "120px",
    marginTop: "50px",
    textAlign: "center",
    color: "#F2A162",
    marginBottom: "100px",
  },
  Text404: {
    fontFamily: "'Montserrat', sans-serif",
    fontStyle: "noraml",
    fontWeight: "bold",
    fontSize: "40px",
    textAlign: "center",
    color: "#333333",
    marginBottom: "30px",
    lineHeight: "45px",
  },
  errorText: {
    fontFamily: "'Montserrat', sans-serif",
    fontStyle: "noraml",
    fontWeight: "500",
    fontSize: "25px",
    textAlign: "center",
    color: "#565752",
    marginBottom: "30px",
    lineHeight: "35px",
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <Container style={{ padding: "50px 0px" }}>
      <Grid
        container
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Grid xs={12} style={{ textAlign: "center" }}>
          <img
            src="https://assets.progton.com/progton-logo.webp"
            alt="logo"
            height="50px"
            style={{ textAlign: "center" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          margin: "20px 0px",
        }}
      >
        <Grid xs={12}>
          <p className={classes.text}>PROGTON SOFTWARE PVT. LTD.</p>
        </Grid>
      </Grid>
      <div style={{ textAlign: "center" }}>
        <p className={classes.oopsText}>Oops!</p>
        <p className={classes.Text404}>404 - PAGE NOT FOUND</p>
        <p className={classes.errorText}>
          The page you are looking for might be unavailable, or <br /> have been
          moved or had its name changed.
        </p>
      </div>
    </Container>
  );
};

export default NotFoundPage;
