import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ServiceTitleBanner from "../../../components/company-services/ServiceTitleBanner";
// import ShowWebDevelopmentServices from "../../../components/web-development/ShowWebDevelopmentServices";
import { progtonData } from "../../../components/details";
import { Container, Grid } from "@material-ui/core";
import Drawer from "../../../components/drawer/Drawer";
import ServicesDetails from "../../../components/data/ServicesDetails";
import NotFoundPage from "../../notfound/NotFoundPage";
import { makeStyles } from "@material-ui/core/styles";
import LazyLoad from "react-lazyload";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subTitle: {
    color: "#19275F",
    fontSize: "22px",
    fontWeight: 700,
    fontFamily: "CerebriSansPro-Regular",
    paddingBottom: "15px",
    paddingTop: "15px",
    wordWrap: "break-word",  
    overflowWrap: "break-word",
    whiteSpace: "normal",  // Ensure text wraps properly
  },
  
  subText: {
    color: "#696969",
    fontSize: "17px",
    letterSpacing: "0em",
    lineHeight: 1.78,
    fontWeight: "500",
    fontFamily: "CerebriSansPro-Regular",
    textAlign: "justify",
    wordWrap: "break-word",  
    overflowWrap: "break-word",
    whiteSpace: "normal",
  },
  leftImg: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
  },
  rightImg: {
    width: "100%",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionlast:{
    [theme.breakpoints.down("sm")]: {
      display:"flex",
      flexDirection:"column-reverse",

    },
  }
}));

const AllService = ({ match }) => {
  const classes = useStyles();
  
  const [data, setData] = useState(null); 
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const studiesDetails = ServicesDetails.find(
      (item) => item.slug === match.params.id
    );
    
    if (studiesDetails) {
      setData(studiesDetails);
    } else {
      setData(null);
    }
  setLoading(false);

  }, [match.params.id]);

  if (loading) {
    return<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
    <CircularProgress />
  </div>
  }
  return (
    <React.Fragment>
      {data ? (
        <>
          <Helmet>
            <title>{progtonData.webdevelopment.title}</title>
            <link rel="canonical" href={progtonData.webdevelopment.canonical} />
            <meta
              name="title"
              content="Web Development Company | Progton"
              data-react-helmet="true"
            />
            <meta
              name="description"
              content={progtonData.webdevelopment.description}
              data-react-helmet="true"
            />
            <meta
              name="keywords"
              content="web development, mobile development, web designing, mobile application development, web application development, ecommerce development, ui/ux, digital marketing, user experience, react native development, react developers, Ruby on Rails consulting, Ruby on Rails development, Ruby on Rails expertise, RoR consultant, RoR consulting, Hire developers, hire ruby developers, hire react developers, staff augmentation, it staffing"
            />
          </Helmet>

          <div
            style={{
              display: "flex",
              minHeight: "100vh",
              flexDirection: "column",
            }}
          >
            <ServiceTitleBanner link={data.name} />

            <Container style={{ display: "flex", flex: 1 }}>
              <div className={classes.drawer}>
                <Drawer data={data.name} />
              </div>

              <div style={{ flex: 1 }}>
                <Container style={{ marginTop: "20px" }}>
                  <Grid
                    container
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {data && (
                      <React.Fragment>
                        <Container>
                          <Grid
                            container
                            spacing={3}
                            className={classes.section}
                            style={{padding:0}}
                          >
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.imageContainer}
                            >
                              <LazyLoad>
                                <img
                                  src={data.about}
                                  alt={`${data.about}`}
                                  className={classes.rightImg}
                                />
                              </LazyLoad>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <p className={classes.subTitle}>
                                {data.name} Services
                              </p>
                              <p className={classes.subText}>
                                {data.about_project}
                              </p>
                            </Grid>
                          </Grid>
                        </Container>
                        <Container>
                          <Grid
                            container
                            spacing={3}
                            className={classes.processContainer}
                          >
                            <Grid
                              item
                              xs={12}
                              className={classes.processContent}
                            >
                              <div style={{ margin: "10px 0px" }}>
                                <p className={classes.subTitle}>
                                  Our {data.name} Expertise
                                </p>
                              </div>

                              {data.the_challenges.map((challenge, index) => (
                                <div key={index} className={classes.subText}>
                                  <strong>
                                    {index + 1}. {challenge.title}
                                  </strong>
                                  <div style={{ marginLeft: "42px" }}>
                                    <ul className={classes.subText}>
                                      {challenge.points.map((point, idx) => (
                                        <li key={idx}>{point}</li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              ))}
                            </Grid>
                          </Grid>
                        </Container>
                        <Container>
                          <Grid
                            container
                            spacing={3}
                            className={classes.section}
                          >
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.imageContainer}
                            >
                              <LazyLoad>
                                <img
                                  src={data.results}
                                  alt={`${data.proven_impacts}`}
                                  className={classes.rightImg}
                                  T
                                />
                              </LazyLoad>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <p className={classes.subTitle}>
                                Why Choose Us for {data.name}?
                              </p>
                              <p className={classes.subText}>
                                {data.proven_impacts.text}
                              </p>
                              <div>
                                <ul className={classes.subText}>
                                  {data &&
                                    data.proven_impacts.map((ele, i) => (
                                      <li key={i} className={classes.subText}>
                                        {ele}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </Grid>
                          </Grid>
                        </Container>

                        <Container>
                          <Grid
                            container
                            spacing={3}
                            className={classes.sectionlast}
              
                          >
                            <Grid item xs={12} md={6}>
                              <p className={classes.subTitle}>Let's Build</p>
                              <p className={classes.subText}>
                                {data.the_results.data } 
                                <a
                                  href="/contact-us"
                                  className={classes.subText}
                                  id="contactForm"
                                  style={{marginLeft:"5px"}}
                                >
                                  {data.the_results.contact}
                                </a>
                              </p>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.imageContainer}
                            >
                              <LazyLoad>
                                <img
                                  src={data.takeaway}
                                  alt={`${data.proven_impacts}`}
                                  className={classes.rightImg}
                                />
                              </LazyLoad>
                            </Grid>
                          </Grid>
                        </Container>
                      </React.Fragment>
                    )}
                  </Grid>
                </Container>
              </div>
            </Container>
          </div>
        </>
      ) : (
        <NotFoundPage />
      )}
    </React.Fragment>
  );
};

export default AllService;
