import React, { useEffect } from 'react';
import Routes from '../src/config/Routes';
import ErrorBoundary from './components/errorBoundary/error-boundary';

const App = () => {

  useEffect(() => {
    const body = document.querySelector('#root');
    body.scrollIntoView({ behavior: "smooth", block: "start" })
    setTimeout(() => {
      body.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 0)

  }, [])

  return (
    <div className="App">
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default App;
